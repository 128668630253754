import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { sliderItems } from "../data";
import { mobile, tablet } from "../responsive";

const Container = styled.div`
    width: 100%;
    height: 80vh;
    display: flex;
    position: relative;
    overflow: hidden;
    ${mobile({ height: "100%" })}
    ${tablet({ height: "100%" })}
`;

const Arrow = styled.div`
    width: 50px;
    height: 50px;
    background-color: #fff7f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props) => props.direction === "left" && "10px"};
    right: ${(props) => props.direction === "right" && "10px"};
    margin: auto;
    cursor: pointer;
    opacity: 0.5;
    z-index: 2;
`;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    transition: all 1.5s ease;
    transform: translateX(${(props) => props.slideIndex * -100}vw);
    ${tablet({ height: "100%" })}
    ${mobile({ height: "100%" })}
`;

const Slide = styled.div`
    width: 100vw;
    height: 80vh;
    display: flex;
    align-items: center;
    background: url("${(props) => props.bg}");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; /* Prevent image repetition */
    cursor: pointer;

    ${(props) => tablet({
        background: `url(${props.mobilebg})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        height: "70vh",
        width: "100vw",
        backgroundRepeat: "no-repeat",
        overflow: "auto"
    })}

    ${(props) => mobile({
        background: `url(${props.mobilebg})`,
        backgroundSize: "cover", /* Adjusted background size */
        backgroundPosition: "center center",
        height: "35vh",
        backgroundRepeat: "no-repeat", /* Ensure no image repetition */
    })}
`;


const Slider = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const handleClick = (direction) => {
        if (direction === "left") {
            setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 10);
        } else {
            setSlideIndex(slideIndex < 10 ? slideIndex + 1 : 0);
        }
    };

    useEffect(() => {
        const autoRotate = (direction) => {
            if (direction === "left") {
                setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 10);
            } else {
                setSlideIndex(slideIndex < 10 ? slideIndex + 1 : 0);
            }
        };

        const intervalId = setInterval(() => {
            autoRotate("right");
        }, 5000);

        return () => clearInterval(intervalId);
    }, [slideIndex]);

    return (
        <Container>
            <Arrow direction="left" onClick={() => handleClick("left")}>
                <ArrowLeftOutlined />
            </Arrow>
            <Wrapper slideIndex={slideIndex}>
                {sliderItems.map((item) => (
                    <Slide mobilebg={item.mobileImg} bg={item.img} key={item.id} onClick={() => window.location.href = item.url}></Slide>
                ))}
            </Wrapper>
            <Arrow direction="right" onClick={() => handleClick("right")}>
                <ArrowRightOutlined />
            </Arrow>
        </Container>
    );
};

export default Slider;
